import React from "react";
import Tablet from "../../images/security-tablet-app.png";
import Customer from "../../images/joseph-ike.png";
import Hazard from "../../images/fire-hazard.png";
import "./security.css";

function Security() {
  return (
    <div id="security">
      <div className="security-left">
        <img src={Tablet} className="tablet-block" alt="text" />
        <img
          src={Customer}
          className="customer-block security-images"
          alt="customer block"
        />
        <img
          src={Hazard}
          className="hazard-block security-images"
          alt="hazard block"
        />
      </div>
      {/* Accordion */}
      <div className="accordion-div">
        <div className="accordion accordion-flush" id="accordionFlushExampleTwo">
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingElev">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseElev"
                aria-expanded="false"
                aria-controls="flush-collapseElev"
              >
                Visitor Booking List
              </button>
            </h2>
            <div
              id="flush-collapseElev"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingElev"
              data-bs-parent="#accordionFlushExampleTwo"
            >
              <div className="accordion-body">
                Keep track of visitors onsite, their identity, their host,
                purpose of visit, time in and time out.
              </div>
            </div>
          </div>
          {/* Flush 11 */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingTwel">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwel"
                aria-expanded="false"
                aria-controls="flush-collapseTwel"
              >
                Check-In Visitors
              </button>
            </h2>
            <div
              id="flush-collapseTwel"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingTwel"
              data-bs-parent="#accordionFlushExampleTwo"
            >
              <div className="accordion-body">
                Process and report when a visitor has been checked in.
              </div>
            </div>
          </div>
          {/* Flush 13 */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingThirt">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThirt"
                aria-expanded="false"
                aria-controls="flush-collapseThirt"
              >
                Check-Out Visitors
              </button>
            </h2>
            <div
              id="flush-collapseThirt"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingThirt"
              data-bs-parent="#accordionFlushExampleTwo"
            >
              <div className="accordion-body">
                Process and report when a visitor has been checked out.
              </div>
            </div>
          </div>
          {/* Flush 14 */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingFourt">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFourt"
                aria-expanded="false"
                aria-controls="flush-collapseFourt"
              >
                Ping Resident
              </button>
            </h2>
            <div
              id="flush-collapseFourt"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingFourt"
              data-bs-parent="#accordionFlushExampleTwo"
            >
              <div className="accordion-body">
                Contact resident by sending a notification about their guest
                access status.
              </div>
            </div>
          </div>
          {/* Flush 15 */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingFifth">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFifth"
                aria-expanded="false"
                aria-controls="flush-collapseFifth"
              >
                Emergency Alarm
              </button>
            </h2>
            <div
              id="flush-collapseFifth"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingFifth"
              data-bs-parent="#accordionFlushExampleTwo"
            >
              <div className="accordion-body">
                Receive and send emergency alerts to be acted upon by security.
              </div>
            </div>
          </div>
          {/* Flush 16 */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingSixt">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseSixt"
                aria-expanded="false"
                aria-controls="flush-collapseSixt"
              >
                Push Notification
              </button>
            </h2>
            <div
              id="flush-collapseSixt"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingSixt"
              data-bs-parent="#accordionFlushExampleTwo"
            >
              <div className="accordion-body">Receive automated messages.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Security;
