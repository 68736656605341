import React from "react";
import { NavHashLink as NavLink } from "react-router-hash-link";
import { animateScroll as scroll } from "react-scroll";
import bayaSmall from "../../images/baya-logo-2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faAddressCard } from "@fortawesome/free-regular-svg-icons";
import "./navbar.css";

function NavBar() {
  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <div className="nav-bar">
      <img
        src={bayaSmall}
        className="baya-small"
        alt="baya logo"
        onClick={scrollToTop}
      />

      <ul className="nav">
        <li className="nav-item">
          <NavLink
            style={{ border: "0px" }}
            to="#contact"
            scroll={(el) =>
              el.scrollIntoView({ behavior: "smooth", block: "start" })
            }
            className="selected contact-select"
          >
            <i className="fa fa-phone"></i>
            <FontAwesomeIcon icon={faAddressCard} className="social-icon" />
            Contact
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            style={{ border: "0px" }}
            to={{
              pathname: "https://api.whatsapp.com/send?phone=2348067857036",
            }}
            target="_blank"
            className="selected contact-select"
          >
            <FontAwesomeIcon icon={faWhatsapp} className="social-icon" />
            +234 701 423 2382
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            style={{ border: "0px" }}
            to={{
              pathname: "https://api.whatsapp.com/send?phone=2348067857036",
            }}
            target="_blank"
            className="selected"
          >
            <FontAwesomeIcon icon={faWhatsapp} className="social-icon" />
            +234 805 673 7856
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

export default NavBar;
