import React from "react";
import Resident from "../../images/resident-mobile-app.png";
import Feed from "../../images/feed.png";
import Payments from "../../images/payments.png";
import Visitors from "../../images/visitors.png";
import "./residents.css";

function Residents() {
  return (
    <div id="residents">
      <div className="residents-left">
        <img src={Resident} className="text-block" alt="text" />
        <img
          src={Feed}
          className="feed-block residents-images"
          alt="feed block"
        />
        <img
          src={Payments}
          className="payments-block residents-images"
          alt="payments block"
        />
        <img
          src={Visitors}
          className="visitors-block residents-images"
          alt="visitors block"
        />
      </div>
      {/* Accordion */}
      <div className="accordion-div">
        <div className="accordion accordion-flush" id="accordionFlushExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                Visitor Booking
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                Tired of calling for access? Generate access codes for your
                guests with ease.
              </div>
            </div>
          </div>
          {/* Flush Two */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
                Service Charge Payments
              </button>
            </h2>
            <div
              id="flush-collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                Pay for services just by funding your wallets and making
                payments at any time.
              </div>
            </div>
          </div>
          {/* Flush Three */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
              >
                Utility Vending
              </button>
            </h2>
            <div
              id="flush-collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingThree"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                Pay directly for electricity, gas, water etc. whenever you want
                to.
              </div>
            </div>
          </div>
          {/* Flush Four */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingFour">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFour"
                aria-expanded="false"
                aria-controls="flush-collapseFour"
              >
                SOS Trigger
              </button>
            </h2>
            <div
              id="flush-collapseFour"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingFour"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                Access to local emergency number. Speed dial at the click of a
                button.
              </div>
            </div>
          </div>
          {/* Flush Five */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingFive">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFive"
                aria-expanded="false"
                aria-controls="flush-collapseFive"
              >
                Complaints
              </button>
            </h2>
            <div
              id="flush-collapseFive"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingfive"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                Send complaints about services directly to the Facility Manager.
              </div>
            </div>
          </div>
          {/* Flush Six */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingSix">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseSix"
                aria-expanded="false"
                aria-controls="flush-collapseSix"
              >
                Polls
              </button>
            </h2>
            <div
              id="flush-collapseSix"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingSix"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                Carry out sampling or collection of opinions on a subject or
                voting.
              </div>
            </div>
          </div>
          {/* Flush Seven */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingSeven">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseSeven"
                aria-expanded="false"
                aria-controls="flush-collapseSeven"
              >
                Market Place
              </button>
            </h2>
            <div
              id="flush-collapseSeven"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingSeven"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                Share your business ideas, artistic creations, goods and
                services.
              </div>
            </div>
          </div>
          {/* Flush Eight */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingEight">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseEight"
                aria-expanded="false"
                aria-controls="flush-collapseEight"
              >
                Notification Feed
              </button>
            </h2>
            <div
              id="flush-collapseEight"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingEight"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                Receive updates and information about daily community activities
                from the Facility Manager and association.
              </div>
            </div>
          </div>
          {/* Flush Nine ::  Exco Inbox (for approvals) */}
          {/* Flush Ten */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingTen">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTen"
                aria-expanded="false"
                aria-controls="flush-collapseTen"
              >
                Push Notification
              </button>
            </h2>
            <div
              id="flush-collapseTen"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingTen"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                Receive automated messages on the go.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Residents;
