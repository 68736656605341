import React from "react";
import NavBar from "./pages/NavBar/navbar";
import Home from "./pages/Home/home";
import Residents from "./pages/Residents/residents";
import Security from "./pages/Security/security";
import Dashboard from "./pages/Dashboard/dashboard";
import Contact from "./pages/Contact/contact";
import "./App.css";

function App() {
  return (
    <div>
      <NavBar />
      <Home />
      <Residents />
      <Security />
      <Dashboard />
      <Contact />
    </div>
  );
}

export default App;
