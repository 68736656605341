import React from "react";
import FMD from "../../images/fm-dashboard.png";
import Dboard from "../../images/dashboard.png";
import Residents from "../../images/residents.png";
import "./dashboard.css";

function Dashboard() {
  return (
    <div id="dashboard">
      <div className="dashboard-left">
        <img src={FMD} className="fm-block" alt="text" />
        <img
          src={Dboard}
          className="dashboard-block dashboard-images"
          alt="dashboard block"
        />
        <img
          src={Residents}
          className="residents-block dashboard-images"
          alt="residents block"
        />
      </div>
      {/* Accordion */}
      <div className="accordion-div">
        <div className="accordion accordion-flush" id="accordionFlushExampleThree">
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingSevten">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseSevten"
                aria-expanded="false"
                aria-controls="flush-collapseSevten"
              >
                Estate Dashboard
              </button>
            </h2>
            <div
              id="flush-collapseSevten"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingSevten"
              data-bs-parent="#accordionFlushExampleThree"
            >
              <div className="accordion-body">
              Get data visualization and analysis that displayed and other important metrics and data points.
              </div>
            </div>
          </div>
          {/* Flush 18 */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingEigten">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseEigten"
                aria-expanded="false"
                aria-controls="flush-collapseEigten"
              >
                Houses and Owners
              </button>
            </h2>
            <div
              id="flush-collapseEigten"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingEigten"
              data-bs-parent="#accordionFlushExampleThree"
            >
              <div className="accordion-body">
              Register all houses and their owners.
              </div>
            </div>
          </div>
          {/* Flush 19 */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingNinten">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseNinten"
                aria-expanded="false"
                aria-controls="flush-collapseNinten"
              >
                Residents
              </button>
            </h2>
            <div
              id="flush-collapseNinten"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingNinten"
              data-bs-parent="#accordionFlushExampleThree"
            >
              <div className="accordion-body">
              Register all residents.
              </div>
            </div>
          </div>
          {/* Flush 20 */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingTwenty">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwenty"
                aria-expanded="false"
                aria-controls="flush-collapseTwenty"
              >
                Payments
              </button>
            </h2>
            <div
              id="flush-collapseTwenty"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingTwenty"
              data-bs-parent="#accordionFlushExampleThree"
            >
              <div className="accordion-body">
              Create and activate payment options for general or specific services, as well as invoices.
              </div>
            </div>
          </div>
          {/* Flush 21 :: Utilities */}
          {/* Flush 22 */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingTwentt">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwentt"
                aria-expanded="false"
                aria-controls="flush-collapseTwentt"
              >
                Emergency Alarm
              </button>
            </h2>
            <div
              id="flush-collapseTwentt"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingTwentt"
              data-bs-parent="#accordionFlushExampleThree"
            >
              <div className="accordion-body">
              Receive and send emergency alerts to be acted upon by security.
              </div>
            </div>
          </div>
          {/* Flush 23 */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingTwentre">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwentre"
                aria-expanded="false"
                aria-controls="flush-collapseTwentre"
              >
                Bank Account and Payment Gateway
              </button>
            </h2>
            <div
              id="flush-collapseTwentre"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingTwentre"
              data-bs-parent="#accordionFlushExampleThree"
            >
              <div className="accordion-body">
              Can support integration of various payment gateway platforms.
              </div>
            </div>
          </div>
          {/* Flush 24 */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingTwentfr">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwentfr"
                aria-expanded="false"
                aria-controls="flush-collapseTwentfr"
              >
                Reports
              </button>
            </h2>
            <div
              id="flush-collapseTwentfr"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingTwentfr"
              data-bs-parent="#accordionFlushExampleThree"
            >
              <div className="accordion-body">
              Generate weekly, monthly, annual or at custom duration.
              </div>
            </div>
          </div>
          {/* Flush 25 */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingTwentfv">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwentFv"
                aria-expanded="false"
                aria-controls="flush-collapseTwentFv"
              >
                Polls and Surveys
              </button>
            </h2>
            <div
              id="flush-collapseTwentFv"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingTwentFv"
              data-bs-parent="#accordionFlushExampleThree"
            >
              <div className="accordion-body">
              Carry out sampling or collection opinions on a subject or voting. 
              </div>
            </div>
          </div>
          {/* Flush 26 */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingTwentSix">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwentSix"
                aria-expanded="false"
                aria-controls="flush-collapseTwentSix"
              >
                Notifications
              </button>
            </h2>
            <div
              id="flush-collapseTwentSix"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingTwentSix"
              data-bs-parent="#accordionFlushExampleThree"
            >
              <div className="accordion-body">
              Receive residents requests and complaints, security updates etc.
              </div>
            </div>
          </div>
          {/* Flush 27 */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingTwentSv">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwentSv"
                aria-expanded="false"
                aria-controls="flush-collapseTwentSv"
              >
                Announcements
              </button>
            </h2>
            <div
              id="flush-collapseTwentSv"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingTwentSv"
              data-bs-parent="#accordionFlushExampleThree"
            >
              <div className="accordion-body">
              Send out messages to residents/users.
              </div>
            </div>
          </div>
          {/* Flush 28 :: Vending */}
          {/* Flush 29 */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingTwentNin">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwentNin"
                aria-expanded="false"
                aria-controls="flush-collapseTwentNin"
              >
                Push Notification
              </button>
            </h2>
            <div
              id="flush-collapseTwentNin"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingTwentNin"
              data-bs-parent="#accordionFlushExampleThree"
            >
              <div className="accordion-body">
              Receive automated messages.
              </div>
            </div>
          </div>
          {/* Flush 30 */}
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingThirty">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThirty"
                aria-expanded="false"
                aria-controls="flush-collapseThirty"
              >
                Send Push Notification
              </button>
            </h2>
            <div
              id="flush-collapseThirty"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingThirty"
              data-bs-parent="#accordionFlushExampleThree"
            >
              <div className="accordion-body">
              Send automated messages to users.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
