import React from "react";
import { useFormik } from "formik";
import Contactus from "../../images/contact-us.png";
import "./contact.css";

const initialValues = {
  name: "",
  email: "",
  number: "",
  subject: "",
  message: "",
};

const onSubmit = (values) => {
  alert("Submission Successful!");

  fetch("https://formsubmit.co/ajax/be4fc2ac990c62336a5ff10786a811cb", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      name: values.name,
      email: values.email,
      number: values.number,
      subject: values.subject,
      message: values.message,
    }),
  })
    .then((response) => response.json())
    .then((data) => console.log(data))
    .catch((error) => console.log(error));
};

const validate = (values) => {
  // values.name values.email values.number values.subject values.message
  // errors.name errors.email errors.number errors.subject errors.message

  let errors = {};

  if (!values.name) {
    errors.name = "Name required";
  }

  if (/^[A-Za-z]+$/.test(values.number)) {
    errors.number = "Invalid number";
  } else if (values.number.length >= 15) {
    errors.number = "Number is too long";
  }

  if (!values.email) {
    errors.email = "Email required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email format";
  }

  if (!values.subject) {
    errors.subject = "Subject required";
  }

  return errors;
};

function Contact() {
  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  });

  return (
    <div id="contact">
      <div className="contact-grid">
        <div className="left-column">
          <img src={Contactus} className="contact-us" alt="contact us" />
          <div className="cont-details">
            <p className="contact-details">
              Peterson Atoe
              <br />
              Tel:- 234(0)8067857036
              <br />
              Email:-
              <a href="mailto: abc@example.com">
                atoe.peterson@opendeskng.com
                <br />
              </a>
            </p>
            <br />
            <br />
            <p className="contact-details">
              McDonnell Ebube
              <br />
              Tel:- 234(0)8056737856
              <br />
              Email:-
              <a href="mailto: abc@example.com">adimabua.ebube@ipsltld.com</a>
            </p>
          </div>
        </div>
        <div className="right-column">
          <form onSubmit={formik.handleSubmit}>
            <div className="form-column">
              <div className="form-cont">
                <label htmlFor="name" className="input-label">
                  Name*
                </label>
                <br />
                <br />
                <input
                  type="text"
                  className="input-fields input-size"
                  id="name"
                  name="name"
                  placeholder="Your Name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  autoComplete="off"
                />
                {formik.errors.name ? (
                  <div className="error-message">{formik.errors.name}</div>
                ) : null}
              </div>
              <div className="form-cont">
                <label htmlFor="number" className="input-label">
                  Contact Number
                </label>
                <br />
                <br />
                <input
                  type="text"
                  className="input-fields input-size"
                  id="number"
                  name="number"
                  placeholder="Your Contact Number"
                  onChange={formik.handleChange}
                  value={formik.values.number}
                  autoComplete="off"
                />
                {formik.errors.number ? (
                  <div className="error-message">{formik.errors.number}</div>
                ) : null}
              </div>

              <div className="form-cont">
                <label htmlFor="email" className="input-label">
                  Email*
                </label>
                <br />
                <br />
                <input
                  type="email"
                  className="input-fields input-size"
                  id="email"
                  name="email"
                  placeholder="example@email.com"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  autoComplete="off"
                />
                <br />
                {formik.errors.email ? (
                  <div className="error-message">{formik.errors.email}</div>
                ) : null}
              </div>

              <div className="form-cont">
                <label htmlFor="name" className="input-label">
                  Subject*
                </label>
                <br />
                <br />
                <input
                  type="text"
                  className="input-fields input-size"
                  id="subject"
                  name="subject"
                  placeholder="Your Message Subject"
                  onChange={formik.handleChange}
                  value={formik.values.subject}
                  autoComplete="off"
                />
                <br />
                {formik.errors.subject ? (
                  <div className="error-message errors">
                    {formik.errors.subject}
                  </div>
                ) : null}
              </div>

              <div className="form-cont">
                <label htmlFor="name" className="input-label">
                  Your Message
                </label>
                <br />
                <br />
                <textarea
                  rows="4"
                  type="text"
                  className="input-fields input-size"
                  id="message"
                  name="message"
                  placeholder="Your Message Here"
                  onChange={formik.handleChange}
                  value={formik.values.message}
                  autoComplete="off"
                />
              </div>
            </div>

            <input
              type="hidden"
              name="_subject"
              value="Baya Info:"
            />
            {/* autoresponse won't work with forms that are disabled reCAPTCHA and forms that are submitting through AJAX.*/}
            <input
              type="hidden"
              name="_autoresponse"
              value="Thank you for contacting Baya! Your message has been received and we'll get back to you shortly."
            />
            <input 
              type="hidden" 
              name="_captcha" 
              value="false" 
            />

            <button type="submit" className="send-button left-margin">
              Send Message
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
