import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  EffectFade,
  Navigation,
  Pagination,
  Autoplay,
} from "swiper";
import "swiper/swiper-bundle.css";
import { NavHashLink as NavLink } from "react-router-hash-link";
import Bird from "../../images/baya-bird-2.jpg";
import Bird2 from "../../images/baya-bird.jpg";
import Bird3 from "../../images/baya-bird-3.gif";
import bayaBig from "../../images/baya.png";
import IOS from "../../images/QR Code-IOS.jpeg";
import Android from "../../images/QR Code-android.jpeg";
import "./home.css";

SwiperCore.use([EffectFade, Navigation, Pagination, Autoplay]);

function Home() {
  return (
    <Swiper
      className="swiper"
      id="main"
      tag="section"
      wrapperTag="ul"
      spaceBetween={0}
      slidesPerView={1}
      effect={"fade"}
      navigation={false}
      pagination={{ clickable: true }}
      autoplay={{ delay: 2500, disableOnInteraction: false }}
      speed={3000}
    >
      <SwiperSlide className="home">
        <div className="left-container">
          <img className="left-image" src={Bird} alt="baya-bird" />
        </div>
        <div className="right-container">
          <div className="center-div">
            <img className="baya-logo" src={bayaBig} alt="baya" />

            <h1 className="baya-manager">Baya</h1>
            <h6 className="baya-estate">Estate Manager</h6>
            <div className="align-links">
              <NavLink
                to="#residents"
                scroll={(el) =>
                  el.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  })
                }
                activeClassName="scroll"
                className="scroll active"
              >
                <span className="checker">&#10003;</span> Resident Mobile App
              </NavLink>

              <br />

              <NavLink
                to="#security"
                scroll={(el) =>
                  el.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  })
                }
                activeClassName="scroll"
                className="scroll active"
              >
                <span className="checker">&#10003;</span> Security Gate App
                <span className="tablets"> for Tabs</span>
                <span className="fm-admin"> for Tablets</span>
              </NavLink>

              <br />

              <NavLink
                to="#dashboard"
                scroll={(el) =>
                  el.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  })
                }
                activeClassName="scroll"
                className="scroll active"
              >
                <span className="checker">&#10003;</span> Facility Manager
                Dashboard
                {/* <span className="fm-admin">(FM) Admin</span> Dashboard */}
              </NavLink>

              <br />
            </div>

            <p className="platform">
              <NavLink
                style={{ border: "0px" }}
                to={{
                  pathname: "https://apps.apple.com/ng/app/baya-resident/id1591887645",
                }}
                target="_blank"
              >
                <img className="platform-logo apple" src={IOS} alt="platform" />
              </NavLink>
              <NavLink
                style={{ border: "0px" }}
                to={{
                  pathname:
                    "https://play.google.com/store/apps/details?id=com.baya.resedent1.live",
                }}
                target="_blank"
              >
                <img className="platform-logo" src={Android} alt="platform" />
              </NavLink>
            </p>
          </div>
        </div>
      </SwiperSlide>
      {/* Second Slide */}
      <SwiperSlide className="home">
        <div className="left-container">
          <img className="left-image" src={Bird2} alt="baya-bird" />
        </div>
        <div className="right-container" style={{ backgroundColor: "white" }}>
          <div className="center-div">
            <img className="baya-logo" src={bayaBig} alt="baya" />

            <h1 className="baya-manager">Baya</h1>
            <h6 className="baya-estate">Estate Manager</h6>

            <NavLink
              to="#residents"
              scroll={(el) =>
                el.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                })
              }
              activeClassName="scroll"
              className="scroll active"
            >
              <span className="checker">&#10003;</span> Resident Mobile App
            </NavLink>
            <br />

            <NavLink
              to="#security"
              scroll={(el) =>
                el.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                })
              }
              activeClassName="scroll"
              className="scroll active"
            >
              <span className="checker">&#10003;</span> Security Gate App
              <span className="tablets"> for Tabs</span>
              <span className="fm-admin"> for Tablets</span>
            </NavLink>
            <br />

            <NavLink
              to="#dashboard"
              scroll={(el) =>
                el.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                })
              }
              activeClassName="scroll"
              className="scroll active"
            >
              <span className="checker">&#10003;</span> Facility Manager
              Dashboard
              {/* <span className="fm-admin">(FM) Admin</span> Dashboard */}
            </NavLink>
            <br />

            <p className="platform">
              <NavLink
                style={{ border: "0px" }}
                to={{
                  pathname: "https://apps.apple.com/ng/app/baya-resident/id1591887645",
                }}
                target="_blank"
              >
                <img className="platform-logo apple" src={IOS} alt="platform" />
              </NavLink>
              <NavLink
                style={{ border: "0px" }}
                to={{
                  pathname:
                    "https://play.google.com/store/apps/details?id=com.baya.resedent1.live",
                }}
                target="_blank"
              >
                <img className="platform-logo" src={Android} alt="platform" />
              </NavLink>
            </p>
          </div>
        </div>
      </SwiperSlide>
      {/* Third Slide */}
      <SwiperSlide className="home">
        <div className="left-container">
          <img className="left-image" src={Bird3} alt="baya-bird" />
        </div>
        <div className="right-container" style={{ backgroundColor: "white" }}>
          <div className="center-div">
            <img className="baya-logo" src={bayaBig} alt="baya" />

            <h1 className="baya-manager">Baya</h1>
            <h6 className="baya-estate">Estate Manager</h6>

            <NavLink
              to="#residents"
              scroll={(el) =>
                el.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                })
              }
              activeClassName="scroll"
              className="scroll active"
            >
              <span className="checker">&#10003;</span> Resident Mobile App
            </NavLink>
            <br />

            <NavLink
              to="#security"
              scroll={(el) =>
                el.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                })
              }
              activeClassName="scroll"
              className="scroll active"
            >
              <span className="checker">&#10003;</span> Security Gate App
              <span className="tablets"> for Tabs</span>
              <span className="fm-admin"> for Tablets</span>
            </NavLink>
            <br />

            <NavLink
              to="#dashboard"
              scroll={(el) =>
                el.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                })
              }
              activeClassName="scroll"
              className="scroll active"
            >
              <span className="checker">&#10003;</span> Facility Manager
              Dashboard
              {/* <span className="fm-admin">(FM) Admin</span> Dashboard */}
            </NavLink>
            <br />

            <p className="platform">
              <NavLink
                style={{ border: "0px" }}
                to={{
                  pathname: "https://apps.apple.com/ng/app/baya-resident/id1591887645",
                }}
                target="_blank"
              >
                <img className="platform-logo apple" src={IOS} alt="platform" />
              </NavLink>
              <NavLink
                style={{ border: "0px" }}
                to={{
                  pathname:
                    "https://play.google.com/store/apps/details?id=com.baya.resedent1.live",
                }}
                target="_blank"
              >
                <img className="platform-logo" src={Android} alt="platform" />
              </NavLink>
            </p>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
}

export default Home;
